import React, {Component} from 'react';
import {StoreID, EventNames, BackgroundJobState} from '../../common/commonTypes';
import Pages from '../../widgets/Pages';
import axios from "axios";
import './route.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import queryString from 'query-string'
import {formatCurrency, formatDateTime, isEmpty} from "../../util/util";
import {UserContext} from "../../context/User";
import CreateNewDeeplink from "./CreateEditRoute";
import Switch from "react-switch";
import {useLocation, Link} from "react-router-dom";
import MenuAppType from "./MenuAppType";
import Button from "../../components/UI/Button"
import RouteItem from './RouteItem';
import CloseIcon from '../../components/icons/CloseIcon';
import UserSelect from '../../components/UserSelect';

const UNIVERSAL_REDIRECT_APP_ID = 1;

const GPLINK_TEMPLATE = 'https://play.google.com/store/apps/details?id={BUNDLE_ID}&url={URL}';
const APPLINK_TEMPLATE = 'https://{DOMAIN}/{ROUTE_APPLINK}';
const DEFAULT_APP_TYPE_FAV_KEY = 'app_type_routes';
const DEFAULT_APP_TYPE = StoreID.APP_STORE;

class RouteList extends Component {
    filterTimer = null;
    state = {
        page: 1,
        isLast: false,
        loading: true,
        routes: [],
        deeplinksToPush: [],
        isNamingOn: false,
        appType: DEFAULT_APP_TYPE,
        filter: '',
        gpLinksMode: false,
        userId: 0
    };

    constructor(props) {
        super(props);
        this.onUserEvent = this.onUserEvent.bind(this);  // Binding here
    }

    componentDidMount() {
        const appType = this.getUser().getFavData(DEFAULT_APP_TYPE_FAV_KEY,DEFAULT_APP_TYPE);
        const params = queryString.parse(this.props.location.search);
        this.fetchData(appType, this.state.page);
        this.setState({ deeplinksToPush: [], isNamingOn: this.getUser().getFavData('is_naming_on',false),
            appType, gpLinksMode: !!params.gplinks });
        this.getUser().registerEventListener(this.onUserEvent.bind(this));

    }

    componentWillUnmount() {
        this.getUser().unregisterEventListener(this.onUserEvent.bind(this));
        if (this.filterTimer !== null) {
            clearTimeout(this.filterTimer);
            this.filterTimer = null;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.loading && !this.state.loading) {
            return;
        }
        let params = queryString.parse(this.props.location.search);
        let page = parseInt(params.page ? params.page : 1);
        if (page < 1) page = 1;
        if (page !== prevState.page && !this.state.loading) {
            //console.log(`[QQQ] {componentDidUpdate} prevState.page:${prevState.page} newPage:${page}`);
            this.fetchData(this.state.appType,page,this.state.filter, this.state.userId);
        }
    }

    onUserEvent(event) {
        if (event.name === EventNames.UPDATE_WHITEPAGE_GENERATING_DONE) {
            const route = this.state.routes.find(r => r.id === event.data.routeID);
            if (route) {
                route.generateWhitepageState = event.data.state ? event.data.state : BackgroundJobState.COMPLETED;
                this.setState({routes: this.state.routes});
            }
        }
    }

    getUser() {
        return this.context;
    }

    async fetchData(appType,page,filter='', userId) {
        this.setState({loading: true/*, deeplinks: []*/});

        
        const sendData = {appType, page, filter};

        if(userId) {
            sendData.user_id = userId;
        }

        let res = await axios.post('/routeslist', sendData);

        if (res.data) {
            let data = res.data;
            if (data.routes) data.routes = data.routes.map(route => ({...route,
                domains: route.domains ? route.domains.split(',') : null,
                games: route.route_apps ? route.route_apps.map(ra => {
                    let {name, app_type, weight, os_type, target} = ra;
                    let landing = '', internalLanding = '';
                    try {
                        const targetData = JSON.parse(target);
                        target = targetData.link;
                        landing = targetData.landing;
                        internalLanding = targetData.internalLanding;
                    } catch (e) {}
                    return {name, appType: parseInt(app_type), weight: parseFloat(weight), routeOSType:
                            parseInt(os_type), target, landing, internalLanding};
                }) : null,
            }));

            /* TEST ICONS */
            /**/
            /*data.routes[0].generateWhitepageState = BackgroundJobState.PROCESSING;
            setTimeout(() => {
                this.onUserEvent({
                    name: EventNames.UPDATE_WHITEPAGE_GENERATING_DONE,
                    data: { routeID: data.routes[0].id, state: BackgroundJobState.FAILED }
                });
            }, 5000);*/
            /**/

            this.setState({...{loading: false, page: page}, ...data, userId: userId || 0});
        }
    }

    isDeeplinkSelectedForPush(deeplinkID) {
        return this.state.deeplinksToPush.find(dID => dID === deeplinkID);
    }

    onSelectDeeplinkForPush(deeplinkID) {
        this.setState({deeplinksToPush:
                this.isDeeplinkSelectedForPush(deeplinkID)
                ? this.state.deeplinksToPush.filter(dID => dID !== deeplinkID)
                : [...this.state.deeplinksToPush, deeplinkID]
        });
    }

    onNamingSwitch(value,e) {
        this.setState({isNamingOn: value});
        this.getUser().setFavData('is_naming_on',value,true);
    }

    onAppTypeSelect(value,e) {
        const appType = value;
        if (this.state.appType !== appType) {
            this.setState({appType});
            this.getUser().setFavData(DEFAULT_APP_TYPE_FAV_KEY, value, true);
            this.fetchData(appType, 1, this.state.filter, this.state.userId);
        }
    }

    onFilterChanged(e) {
        this.setState({filter: typeof(e) === 'object' ? e.target.value : e});
        if (this.filterTimer !== null) {
            clearTimeout(this.filterTimer);
            this.filterTimer = null;
        }
        this.filterTimer = setTimeout(() => {
            this.fetchData(this.state.appType,1,this.state.filter, this.state.userId);
        }, 500);
    }


    handleUserSelectChange = (userId) => {
        this.fetchData(this.state.appType,1, this.state.filter, userId);
    }

    render() {
        const {routes,deeplinksToPush,page,isLast,loading,total_installs,
            isNamingOn,appType,filter,gpLinksMode,userId} = this.state;

        const wideLinkDesign = appType === StoreID.APP_STORE || StoreID.PWA || appType === StoreID.UNIVERSAL;

        const _getDeeplink = (route) => isNamingOn ? route.naming : (!gpLinksMode ? route.deeplink
                : GPLINK_TEMPLATE.replace('{BUNDLE_ID}', route.bundle_id || '')
                                 .replace('{URL}', encodeURIComponent(route.deeplink)) );

        const _getAppLink = (route) => APPLINK_TEMPLATE.replace('{DOMAIN}',
            (route.domains && route.domains.length > 0) ? route.domains[0] : '...')
                        .replace('{ROUTE_APPLINK}', route.applink);

        const _getRouteLink = (route) => (appType === StoreID.GOOGLE_PLAY) ? _getDeeplink(route) : _getAppLink(route);

        return (
            <div className={`page deeplinks ${userId ? 'hide-actions' : ''}`}>
                <h1>Потоки</h1>
                <div style={{marginTop: 16}}>
                    <div className='deeplinks-header'> 
                        <div className='stub'/>
                        <MenuAppType activeAppType={appType} onSelect={this.onAppTypeSelect.bind(this)}/>
                        <div className='user-select-block'>
                            <UserSelect selectedUserId={userId} setSelectedUserId={this.handleUserSelectChange}/>
                        </div>
                    </div>
                </div>

                <div className='filter-block'>
                    <div className='left-block'>
                        <Link to={`/newflow?at=${appType}`}><Button title='Создать' withoutRounded /></Link>
                        {/*[NAMING]*/ appType === StoreID.GOOGLE_PLAY && this.getUser().isNamingAvailable() &&
                         <div className='switch-block'>
                            <label className='clickable smallerText' htmlFor="namingSwitch">Нейминг</label>
                            <Switch
                            checked={isNamingOn}
                            onChange={this.onNamingSwitch.bind(this)}
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            height={14}
                            width={35}
                            className={`react-switch ${isNamingOn ? "checked": "not-checked"}`}
                            id="namingSwitch"
                            />
                         </div>
                        }
                        {/*[PUSH]*/ !isEmpty(deeplinksToPush) > 0 &&
                        <div className='push-block'>
                            <Link to={{pathname: '/newpush', deeplinks: deeplinksToPush}}>
                                <button className={"green inRow"}>
                                    <div className="icon pushPressedIcon"/>
                                    <div className="inCol">
                                        <div>Пушнуть</div>
                                        <div className="smallText">выбрано: {deeplinksToPush.length}</div>
                                    </div>
                                </button>
                            </Link>
                        </div>
                        }
                    </div>
                    <div className='right-block'>
                        <div className='search-block'>
                        <input id="filter" name="filter" type="text" value={filter} placeholder='Фильтр...' onChange={this.onFilterChanged.bind(this)} />
                        {filter && 
                            <button onClick={this.onFilterChanged.bind(this,'')} >
                                <CloseIcon/>
                            </button>}
                        </div>
                        <div className='total-installs-block'>
                            <div>Всего инсталлов:</div>
                            <div><b>{total_installs}</b></div>
                        </div>
                    </div>
                </div>

                <div className='route-list'>
                    {routes.map((route) => {
                        if (!loading) {
                            route.routeLink = _getRouteLink(route);
                        }

                        return <RouteItem
                                key={route.id}
                                appType={appType}
                                {...route} 
                                onCopy={() => this.setState({ copied: true })}
                                wideLinkDesign={wideLinkDesign} 
                                isNamingOn={isNamingOn} 
                                gpLinksMode={gpLinksMode} />
                    })}
                </div>
                <div className="footer">
                    <div style={{width: '120px'}}/>
                    <Pages to="/" enabled={!loading} current={page} isLast={isLast}/>
                    <div style={{width: '120px'}}/>
                </div>
            </div>
        );
    }
}
RouteList.contextType = UserContext;

export default props => <RouteList location={useLocation()} {...props}/>;