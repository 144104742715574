import axios from "axios";
import { memo, useState } from "react";
import ImageUploader from "../../components/UI/ImageUploader";
import InputWithLabel from "../../components/UI/InputWithLabel";
import { formatRating, getResponseErrorsList } from "../../util/util";
import TextareaWithLabel from "../../components/UI/TextareaWithLabel";
import Button from "../../components/UI/Button";
import SelectWithLabel from "../../components/UI/SelectWithLabel";
import {
    ANSWER_OPTIONS,
    DATE_SELECT_OPTIONS,
    DISALLOWED_NUMBER_SYMBOLS,
} from "../../constants/pwa";
import { ImageType } from "../../common/commonTypes";
import ImageWithModal from "../../components/UI/ImageWithModal";
import Loader from "../../components/UI/Loader";

const Comment = ({
    commentData,
    id,
    update,
    seed,
    indexAutoResponse,
    deleteComment,
    createErrorMessage,
    activeLng,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const {
        avatar,
        auth,
        comment,
        date,
        like,
        rating,
        resComment,
        answerType,
    } = commentData;

    const updateComment = (key, value, isNotUpdateLastUpdatedTime) => {
        const commentCopy = { ...commentData };
        commentCopy[key] = value;

        update(commentCopy, isNotUpdateLastUpdatedTime);
    };

    const handlerAnswerTypeChange = async (filedName, newValue) => {
        const commentCopy = {
            ...commentData,
            answerType: newValue,
            resComment: "",
        };

        update(commentCopy);
        if (newValue === "absent" || newValue === "custom") {
            return;
        }
        setIsLoading(true);
        try {
            const { data } = await axios.post("/pwa_comment_auto_response", {
                lang: activeLng,
                seed,
                index: indexAutoResponse,
            });
            commentCopy.resComment = data;
            update(commentCopy);
        } catch (err) {
            createErrorMessage(getResponseErrorsList(err));
        }
        setIsLoading(false);
    };

    return (
        <div className="comment">
            <div className="author-block">
                <div className="up-block">
                    <div className="">
                        {avatar ? (
                            <ImageWithModal
                                src={`/images/${avatar}`}
                                onDelete={() =>
                                    updateComment("avatar", "", true)
                                }
                            />
                        ) : (
                            <ImageUploader
                                label="Иконка"
                                onChange={updateComment}
                                fieldName="avatar"
                                imageType={ImageType.AVATAR}
                            />
                        )}
                    </div>
                    <div className="">
                        <div className="filed-block two-fields">
                            <InputWithLabel
                                label="Автор"
                                value={auth}
                                fieldName="auth"
                                onChange={updateComment}
                                blockId={id + "auth"}
                            />
                            <SelectWithLabel
                                options={DATE_SELECT_OPTIONS}
                                value={date}
                                onChange={updateComment}
                                label="Дата"
                                fieldName="date"
                                blockId={id + "date"}
                            />
                        </div>

                        <div className="filed-block two-fields">
                            <InputWithLabel
                                label="Рейтинг"
                                value={rating}
                                min={1}
                                max={5}
                                disallowedSymbols={[...DISALLOWED_NUMBER_SYMBOLS, ".", ","]}
                                fieldName="rating"
                                onChange={updateComment}
                                onBlur={() =>
                                    updateComment(
                                        "rating",
                                        +rating > 5 ? 5 : (+rating).toFixed(0)
                                    )
                                }
                                step="1"
                                type="number"
                                blockId={id + "rating"}
                            />
                            <div>
                                <InputWithLabel
                                    label="Лайки"
                                    min={1}
                                    max={5000}
                                    disallowedSymbols={
                                        DISALLOWED_NUMBER_SYMBOLS
                                    }
                                    value={like}
                                    fieldName="like"
                                    onChange={updateComment}
                                    type="number"
                                    blockId={id + "like"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="filed-block">
                    <TextareaWithLabel
                        label="Комментарий"
                        value={comment}
                        onChange={updateComment}
                        fieldName="comment"
                        minHeight={76} // 4 lines visible
                        blockId={id + "comment"}
                    />
                </div>
            </div>
            <div className="answer-block">
                <div>
                    <div className="two-fields">
                        <SelectWithLabel
                            fieldName="answerType"
                            value={answerType}
                            options={ANSWER_OPTIONS}
                            label="Комментарий ответа"
                            onChange={handlerAnswerTypeChange}
                        />
                    </div>
                    {answerType !== "absent" && (
                        <div
                            className="filed-block"
                            style={{ position: "relative" }}
                        >
                            <TextareaWithLabel
                                value={resComment}
                                onChange={updateComment}
                                fieldName="resComment"
                                disabled={isLoading}
                                minHeight={105}
                            />
                            {isLoading && <Loader />}
                        </div>
                    )}
                </div>
                <div className="actions-block">
                    <Button
                        title="Удалить"
                        variant="error"
                        withoutRounded
                        size="small"
                        onClick={deleteComment}
                    />
                </div>
            </div>
        </div>
    );
};

export default memo(Comment, (prevProps, nextProps) => {
    return (
        JSON.stringify(prevProps.commentData) ===
            JSON.stringify(nextProps.commentData) &&
        prevProps.indexAutoResponse === nextProps.indexAutoResponse
    );
});
