import Textarea from "./Textarea";

const TextareaWithLabel = ({
    label,
    value,
    onChange,
    fieldName,
    disabled,
    minHeight = 60,
    id = "",
    blockId = "",
}) => (
    <div className="filed-block" id={blockId}>
        <label>{label}</label>
        <Textarea
            style={{ minHeight }}
            value={value || ""}
            disabled={disabled}
            onChange={(e) => onChange(fieldName, e.target.value)}
            id={id}
        />
        <div className="error-msg"></div>
    </div>
);

export default TextareaWithLabel;
