import axios from "axios";
import { useEffect, useState } from "react";
import ModalWindow from "../../widgets/ModalWindow";
import EditIcon from "../../components/icons/EditIcon";
import Button from "../../components/UI/Button";

const LandingModal = ({ landingName, fields, onChange, isChanged }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [defaultDynamicFields, setDefaultDynamicFields] = useState({});
    const [dynamicFields, setDynamicFields] = useState({});

    const updateDynamicField = (key, event) => {
        setDynamicFields((prev) => ({ ...prev, [key]: event.target.value }));
    };

    const closeModal = () => setIsOpen(false);
    const openModal = () => setIsOpen(true);

    const saveData = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const isChangedFields =
            JSON.stringify(defaultDynamicFields) !==
            JSON.stringify(dynamicFields);

        setIsDisabled(true);
        onChange({ ...dynamicFields, isChangedFields });

        setTimeout(() => {
            setIsDisabled(false);
            closeModal();
        }, 100);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {
                    data: { dynamic_fields, geo_list },
                } = await axios.post("/landing_template_data", {
                    landing_id: landingName,
                });

                setLanguages(geo_list);
                let obj = {};
                Object.keys(dynamic_fields).forEach((fieldName) => {
                    obj[fieldName] = dynamic_fields[fieldName].default;
                });
                setDefaultDynamicFields(obj);
                obj = { ...obj, ...fields };
                delete obj.isChangedFields;
                setDynamicFields(obj);
            } catch (err) {
                console.log(err);
                setLanguages(null);
                setDynamicFields({});
            }
        };

        fetchData();
    }, [landingName, fields]);

    return (
        <div id="landing-modal-wrapper">
            {languages?.length || Object.keys(dynamicFields).length ? (
                <button
                    className={`edit-btn ${isChanged ? "changed" : ""}`}
                    onClick={openModal}
                    type="button"
                >
                    <EditIcon />
                </button>
            ) : (
                ""
            )}

            <ModalWindow
                isVisible={isOpen}
                onClose={closeModal}
                anchorContainerID="landing-modal-wrapper"
                closeButton
                className="landing-modal"
            >
                <form onSubmit={saveData}>
                    <h3>{landingName}</h3>
                    {languages && (
                        <div className="landing-modal-row">
                            <label>Доступные гео:</label>
                            <div className="readOnlyCode">
                                {languages.map((geo) => (
                                    <span key={geo} className="tinyItem">
                                        {geo}
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}

                    {Object.keys(dynamicFields).map((filedName) => {
                        const label =
                            filedName.charAt(0).toUpperCase() +
                            filedName.slice(1);
                        return (
                            <div className="landing-modal-row" key={filedName}>
                                <label>{label}:</label>
                                <input
                                    type="text"
                                    required
                                    value={dynamicFields[filedName]}
                                    onChange={(e) =>
                                        updateDynamicField(filedName, e)
                                    }
                                />
                            </div>
                        );
                    })}

                    {Object.keys(dynamicFields).length ? (
                        <>
                            <div className="tip warning-block">
                                Значения полей изменятся только на данном потоке
                            </div>
                            <Button
                                style={{ marginTop: 10 }}
                                title="Сохранить"
                                withoutRounded
                                size="small"
                                type="submit"
                                isLoading={isDisabled}
                            />
                        </>
                    ) : (
                        ""
                    )}
                </form>
            </ModalWindow>
        </div>
    );
};

export default LandingModal;
