import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

export const useBlocker = (blocker, when) => {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
        if (!when) return;

        const push = navigator.push;

        navigator.push = (...args) => {
            blocker(() => push(...args));
        };

        return () => {
            navigator.push = push;
        };
    }, [blocker, when, navigator]);
};
