import { Tooltip as ReactTooltip } from "react-tooltip";
import "./languagesTabs.css";
import CloseIcon from "../icons/CloseIcon";
import { LANGUAGES, MAX_LANGUAGES } from "../../constants/pwa";
import { useState } from "react";
import WarningModal from "./WarningModal";
import Button from "./Button";

const LanguagesTabs = ({
    addLanguage,
    activeLng,
    setActiveLng,
    deleteLng,
    items,
    languagesList,
    isDisabledAddButton,
}) => {
    const [isShowWarningModal, setIsShowWarningModal] = useState(false);
    const [langName, setLangName] = useState(null);

    const closeWarningModal = () => setIsShowWarningModal(false);
    const openWarningModal = () => setIsShowWarningModal(true);

    const onDeleteLang = (lang) => {
        setLangName(lang);
        openWarningModal();
    };

    return (
        <>
            <WarningModal
                anchorContainerID="create-edit-pwa"
                isVisible={isShowWarningModal}
                text="Удалить язык?"
                closeBtnVariant="success"
                confirmBtnVariant="error"
                confirmText="Удалить"
                onClose={closeWarningModal}
                onConfirm={() => {
                    deleteLng(langName);
                    closeWarningModal();
                }}
            />
            <div
                className={`languages-tabs-container ${
                    items ? "without-border-b" : ""
                } ${items && Object.keys(items).length ? "" : "without-langs"}`}
            >
                <div className="languages-tabs">
                    {items &&
                        Object.keys(items).map((key) => (
                            <div
                                key={key}
                                className={`languages-tab ${
                                    key === activeLng ? "active" : ""
                                }`}
                                onClick={() => setActiveLng(key)}
                                data-tooltip-content={LANGUAGES[key]}
                                data-tooltip-id={`${key}-tab`}
                            >
                                {key}
                                <button
                                    type="button"
                                    className="close-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onDeleteLang(key);
                                    }}
                                >
                                    <CloseIcon />
                                </button>
                                <ReactTooltip
                                    id={`${key}-tab`}
                                    type="light"
                                    effect="solid"
                                />
                            </div>
                        ))}
                    {items &&
                        Object.keys(items).length !== 0 &&
                        languagesList &&
                        Object.keys(items).length !==
                            Object.keys(languagesList).length && (
                            <button
                                id="add-language-btn"
                                className="languages-tab add-tab"
                                onClick={addLanguage}
                                disabled={isDisabledAddButton}
                                data-tooltip-content={`Максимальное количество ${MAX_LANGUAGES}`}
                                data-tooltip-id="add-language-btn"
                            >
                                <CloseIcon /> <span>Добавить язык</span>
                            </button>
                        )}
                    {(!items || !Object.keys(items).length) &&
                        languagesList && (
                            <Button
                                title={
                                    <>
                                        <CloseIcon /> <span>Добавить язык</span>
                                    </>
                                }
                                variant="warning"
                                withoutRounded
                                isDisabled={isDisabledAddButton}
                                onClick={addLanguage}
                                size="small"
                            />
                        )}
                    {isDisabledAddButton && (
                        <ReactTooltip
                            id={"add-language-btn"}
                            type="light"
                            effect="solid"
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default LanguagesTabs;
