import { useEffect, useId, useMemo, useState } from "react";
import Select from "react-select";
import LandingModal from "./LandingModal";

const LandingConfigurator = ({
    label,
    landingTemplates,
    handleIsLandingChange,
    handleParamChange,
    handleLandingChange,
    isActive,
    landing,
    landingFields,
}) => {
    const id = useId();
    const [landingsConfig, setLandingsConfig] = useState({});
    const [isValidLanding, setIsValidLanding] = useState(true);

    const handlerFieldsSave = (fields) => {
        setLandingsConfig((prev) => ({
            ...prev,
            [landing]: { ...fields },
        }));

        handleParamChange(fields);
    };

    const landingTemplatesOptions = useMemo(() => {
        if (!landingTemplates) return [];
        return landingTemplates.map((landing) => ({
            value: landing,
            label: landing + (landingsConfig[landing]?.isChangedFields ? "*" : ""),
            color: landingsConfig[landing]?.isChangedFields ? "#ff9f43" : "",
        }));
    }, [landingTemplates, landingsConfig]);

    const selectedValue = useMemo(() => {
        let value = "";

        if (!landingTemplates) return value;

        if (landing) {
            value = {
                label: landing + (landingsConfig[landing]?.isChangedFields ? "*" : ""),
                value: landing,
            };
            setIsValidLanding(landingTemplates.includes(landing));
        }

        return value;
    }, [landing, landingTemplates, landingsConfig]);

    const changeOptionStyles = (provided, { data }) => ({
        ...provided,
        color: data.color ? `${data.color} !important` : "",
    });

    const handlerLandingChange = ({ value }) => {
        handleLandingChange(value);
        handleParamChange(landingsConfig[value] || {});
    };

    useEffect(() => {
        if (landingFields) {
            setLandingsConfig((prev) => ({
                ...prev,
                [landing]: landingFields,
            }));
        }
    }, []);

    return (
        <div className="landing-configurator">
            <input
                id={`dist_${id}`}
                type="checkbox"
                className="form-check-input"
                checked={isActive}
                onChange={(e) => handleIsLandingChange(e.target.checked)}
            />
            <label htmlFor={`dist_${id}`}>{label}:</label>
            <div style={{ minWidth: "103px" }}>
                <Select
                    className="select traffic-distribution-select"
                    options={landingTemplatesOptions}
                    isDisabled={!isActive}
                    required
                    value={selectedValue}
                    isSearchable={false}
                    styles={{ option: changeOptionStyles }}
                    onChange={handlerLandingChange}
                />
            </div>
            <div style={{ width: "26px" }}>
                {isActive && (
                    <LandingModal
                        landingName={landing}
                        fields={landingFields}
                        onChange={handlerFieldsSave}
                        isChanged={landingsConfig[landing]?.isChangedFields}
                    />
                )}
            </div>
            {!isValidLanding && <div className="error-msg disabled">Лендинг невозможен для установки</div>}
        </div>
    );
};

export default LandingConfigurator;
