import BasePage from "../base/BasePage";
import {RestrictedArea} from "../../wrappers/RestrictedArea";
import {UserGroup} from "../../context/User";
import {Link} from "react-router-dom";
import Button from "../../components/UI/Button";
import SelectClient from "../../widgets/admin/SelectClient";
import {formatClientName, formatCurrency, formatDateTime, formatPercent, pick} from "../../common/commonUtil";
import React from "react";
import AdminGameStats from "./AdminGameStats";
import axios from "axios";
import {getResponseErrorsList} from "../../util/util";
import {margin} from "clean-css/lib/optimizer/level-1/property-optimizers";
import Pages from "../../widgets/Pages";
import OutsideHandler from "../../widgets/OutsideHandler";
import Textarea from "../../components/UI/Textarea";
import Picker from "@emoji-mart/react";
import i18n from '@emoji-mart/data/i18n/ru.json'

class AdminTestPush extends BasePage {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            executing: false,
            showEmojiPicker: null
        }
    }

    componentDidMount() {
    }

    async onSubmit(event) {
        event.preventDefault();

        if (!this.busy) {
            try {
                this.busy = true;
                this.setState({executing: true, response: null});
                let res = await axios.post('/testpush', {...pick(this.state,
                        ['domain','sub_key_1','sub_value_1','sub_key_2','sub_value_2','device_id','title','message']) });
                this.setState({executing: false, response: res.data, errors: {}});
            } catch (err) {
                this.setState({executing: false, errors: getResponseErrorsList(err)});
            }
            this.busy = false;
        }
    }

    showEmojiPicker(emojiPicker) {
        this.setState({showEmojiPicker: (this.state.showEmojiPicker !== emojiPicker) ? emojiPicker : ''})
    }
    onEmojiSelect(field,emoji) {
        this.setState({[field]: (this.state[field] ? this.state[field] : '') + emoji.native});
    }

    renderPage() {
        const { executing, showEmojiPicker, response, device_id } = this.state;

        const inputField = (fieldName,description,tip,wide=false,disabled=false) => (
            [
                <label htmlFor={fieldName} style={{margin: '8px'}}>{description + (description ? ':' : '')}</label>,
                <input id={fieldName} name={fieldName} type="text" onChange={this.onFieldChanged.bind(this,fieldName)}
                       value={this.state[fieldName]} placeholder={tip} style={{width: wide ? '360px' : '120px',
                    opacity: disabled ? 0.3 : 1.0}} disabled={disabled}/>
            ]
        );

        let _this = this;
        const renderEmojiPicker = (field) => (
            <div className="" style={{textAlign: 'center', position: 'relative'}}>
                <Picker style={{ position: 'absolute', zIndex: '1000', top: '-5px', right: '0px' }} title='Подберите эмодзи…'
                        i18n={i18n}
                        set="google" emojiSize={20} perLine={8} sheetSize={32}
                        onSelect={_this.onEmojiSelect.bind(_this,field)}
                />
            </div>
        );

        let lastDeviceID;

        return (
            <RestrictedArea allowedTo={UserGroup.DEVELOPER}>
                <div className="page">
                    <h1>Тест пушей</h1>
                    <form className='left-align' onSubmit={this.onSubmit.bind(this)} style={{maxWidth: 'none'}}>
                        <ul>
                            <li className="field">
                                {inputField("domain","Домен","",true, !!device_id)}
                            </li>
                            <li className="field" style={{marginLeft: '60px'}}>
                                {inputField("sub_key_1","", "Саб", false, !!device_id)}
                                {inputField("sub_value_1","", "Значение", false, !!device_id)}
                            </li>
                            <li className="field" style={{marginLeft: '60px'}}>
                                {inputField("sub_key_2","", "Саб", false, !!device_id)}
                                {inputField("sub_value_2","", "Значение", false, !!device_id)}
                            </li>
                            <li className="field">
                                {inputField("device_id","Device ID","",true)}
                                {this.renderError("device_id")}
                            </li>

                            <li className="field" style={{marginTop: '30px'}}>
                                <label htmlFor="title" style={{margin: '8px'}}>Заголовок: </label>
                                <input id="title" style={{width: '360px'}}
                                    className="emoji-text" name="title" type="text" onChange={this.onFieldChanged.bind(this,"title")}
                                    value={this.state.title}/>
                                {/*<div className="holder">
                                    <div className="holder wide inRow rightAlign">
                                        <button type="button" style={{top: '16px', right: '4px'}} className="secondary small floating" onClick={this.showEmojiPicker.bind(this,"title")}><div className="icon emoji"/></button>
                                    </div>
                                    {showEmojiPicker === 'title' && <div className="holder wide inRow rightAlign"><OutsideHandler onClick={this.showEmojiPicker.bind(this,"")}>{renderEmojiPicker("title")}</OutsideHandler></div>}
                                </div>*/}
                                {this.renderError("title")}
                            </li>

                            <li className="field">
                                <label htmlFor="message" style={{margin: '8px'}}>Сообщение: </label>
                                <div style={{width: '360px'}}><Textarea
                                    style={{height: '150px'}}
                                    id="message" className="wide emoji-text" name="message" maxLength="1024"
                                    onChange={this.onFieldChanged.bind(this,"message")} value={this.state.message}/></div>
                                {/*<div className="holder">
                                    <div className="holder wide inRow rightAlign">
                                        <button type="button" style={{top: '16px', right: '4px'}} className="secondary small floating" onClick={this.showEmojiPicker.bind(this,"message")}><div className="icon emoji"/></button>
                                    </div>
                                    {showEmojiPicker === 'message' && <div className="holder wide inRow rightAlign"><OutsideHandler onClick={this.showEmojiPicker.bind(this,"")}>{renderEmojiPicker("message")}</OutsideHandler></div>}
                                </div>*/}
                                {this.renderError("message")}
                            </li>


                            <li className="submit" style={{marginTop: '30px'}}>
                                <div>
                                    <div>
                                        <Button
                                            type='submit'
                                            withoutRounded
                                            title='Отправить'
                                            isLoading={executing}
                                        />
                                    </div>
                                    {this.renderRestErrors()}
                                    {this.state.execMsg &&
                                        <div className="tinyTip holder">{this.state.execMsg}</div>
                                    }
                                </div>
                            </li>
                        </ul>

                        <div style={{marginTop: '30px'}}>
                            {response &&
                                <div>
                                    <h2>Статус:</h2>
                                    <div className='readOnlyCode'>{JSON.stringify(response,2,null)}</div>
                                </div>}
                        </div>
                    </form>

                </div>

            </RestrictedArea>
        );
    }

}

export default AdminTestPush;