import { memo } from "react";

const InputWithLabel = ({
    label,
    value,
    onChange,
    fieldName,
    min = "",
    max = "",
    type = "text",
    disallowedSymbols = [],
    step = "1",
    onBlur = () => {},
    inputId = "",
    blockId = "",
}) => (
    <div className="filed-block" id={blockId}>
        <label>{label}</label>
        <input
            id={inputId}
            type={type}
            min={min}
            max={max}
            step={step}
            value={value || ""}
            onBlur={onBlur}
            onChange={(e) => onChange(fieldName, e.target.value)}
            onKeyDown={(e) => {
                if (disallowedSymbols.includes(e.key)) {
                    e.preventDefault();
                }
            }}
        />
        <div className="error-msg"></div>
    </div>
);

export default InputWithLabel;
