const DuplicateIcon = () => (
    <svg
        className="duplicate-icon"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
    >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
            <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Dribbble-Light-Preview"
                    transform="translate(-260.000000, -4479.000000)"
                    fill="#000000"
                >
                    <g id="icons" transform="translate(56.000000, 160.000000)">
                        <path
                            d="M221,4333 L220,4333 L220,4325 C220,4323.895 219.105,4323 218,4323 L210,4323 L210,4322 C210,4321.448 210.448,4321 211,4321 L221,4321 C221.552,4321 222,4321.448 222,4322 L222,4332 C222,4332.552 221.552,4333 221,4333 L221,4333 Z M218,4333 L211,4333 C210.448,4333 210,4332.552 210,4332 L210,4325 L217,4325 C217.552,4325 218,4325.448 218,4326 L218,4333 Z M217,4337 L207,4337 C206.448,4337 206,4336.552 206,4336 L206,4326 C206,4325.448 206.448,4325 207,4325 L208,4325 L208,4333 C208,4334.105 208.895,4335 210,4335 L218,4335 L218,4336 C218,4336.552 217.552,4337 217,4337 L217,4337 Z M222,4319 L210,4319 C208.895,4319 208,4319.895 208,4321 L208,4323 L206,4323 C204.895,4323 204,4323.895 204,4325 L204,4337 C204,4338.105 204.895,4339 206,4339 L218,4339 C219.105,4339 220,4338.105 220,4337 L220,4335 L222,4335 C223.105,4335 224,4334.105 224,4333 L224,4321 C224,4319.895 223.105,4319 222,4319 L222,4319 Z"
                            id="duplicate_round-[#835]"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default DuplicateIcon;
