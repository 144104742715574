import "./button.css";
import Loader from "./Loader";

const Button = ({
    title,
    size = "",
    isDisabled,
    style = {},
    withoutRounded,
    isLoading,
    variant = "success",
    type = "button",
    fullFill,
    customClassName = "",
    onClick = () => {},
}) => {
    const getClassName = () => {
        let className = `custom-btn ${customClassName} ${variant} ${size} `;
        if (withoutRounded) {
            className += "without-rounded ";
        }

        if (isLoading) {
            className += "is-loading ";
        }

        if (fullFill) {
            className += "full-fill ";
        }
        return className;
    };
    return (
        <button
            type={type}
            className={getClassName()}
            onClick={onClick}
            disabled={isDisabled || isLoading}
            style={style}
        >
            <span>{title}</span>
            {isLoading && (
                <Loader
                    variant={`${variant} ${fullFill ? "white" : ""}`}
                    type="loader-btn"
                />
            )}
        </button>
    );
};
export default Button;
